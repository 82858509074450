import request from '@/utils/request'

// 获取启动命令
export const getDentalCAD: any = (data: any) => {
    console.log(data)
    return request({
        url: '/dentalCAD/url',
        method: 'post',
        data
    })
}