import request from '@/utils/request'


export const searchUser: any = (data: any) => {
    return request({
        url: '/sys/user/search',
        method: 'post',
        data
    })
}

export const getMySubordinateUsers: any = () => {
    return request({
        url: '/sys/user/getMySubordinateUsers',
        method: 'get',
    })
}

export const setUserRoles: any = (data: any) => {
    return request({
        url: `/sys/user/roles`,
        method: 'post',
        data
    })
}

export const forbiddenOrRestoreUser: any = (data: any) => {
    return request({
        url: `/sys/user/forbiddenOrRestoreUser/${data.id}`,
        method: 'put'
    })
}