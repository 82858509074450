import request from '@/utils/request'


export const saveOrUpdateProcessingOrder: any = (data: any) => {
    return request({
        url: `/processingOrder`,
        method: 'post',
        data
    })
}

export const pageListProcessingOrder: any = (data: any) => {
    return request({
        url: `/processingOrder/pageList`,
        method: 'post',
        data
    })
}
export const send: any = (expressDelivery: any) => {
    return request({
        url: `/processingOrder/setSend`,
        method: 'put',
        data: expressDelivery
    })
}


export const reAssignProduct: any = (data: any) => {
    return request({
        url: `/processingOrder/reAssignProduct`,
        method: 'put',
        data
    })
}

export const cancelProcessingOrder: any = (id: string) => {
    return request({
        url: `/processingOrder/cancelProcessingOrder/${id}`,
        method: 'put',
    })
}